import findIndex from 'lodash-es/findIndex';
import { Actions } from '../actions';

const initialState = {
  isFetching: false,
  isAuthenticated: false,
  usersOfCluster: {},
  usersByID: {},
  karnottApplications: [],
  partners: [],
};

const user = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case Actions.LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        user: undefined,
      };
    case Actions.LOGIN_SUCCESS:
      return {
        ...initialState, // need to reset state because of new login => because of `en tant que` functionnality, the state can be NOT RESETTED
        ...payload,
        token: `${payload.token}`,
        expireDate: payload.expireDate,
        isAuthenticated: true,
        isFetching: false,
      };
    case Actions.USER_INFO_RETRIEVED:
    case Actions.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case Actions.RETRIEVE_USERS_OF_CLUSTER_SUCCESS: {
      const clusterId = payload.cluster_id;
      return {
        ...state,
        usersOfCluster: {
          ...state.usersOfCluster,
          [clusterId]: payload.users,
        },
      };
    }
    case Actions.RECEIVE_USERS: {
      const usersByID = {};
      (payload || []).forEach((user) => {
        usersByID[user.id] = user;
      });
      return {
        ...state,
        usersByID,
      };
    }
    case Actions.UPDATE_DRIVER_SUCCESS:
      return {
        ...state,
        usersByID: {
          ...state.usersByID,
          [payload.id]: payload,
        },
      };
    case Actions.RETRIEVE_USERS_OF_CLUSTERS_SUCCESS:
      return {
        ...state,
        usersOfCluster: payload,
      };
    case Actions.USER_LINK_SUCCESS:
    case Actions.DRIVER_AND_BEACON_LINKED: {
      const theClusterId = payload.cluster_id;
      const { usersOfCluster } = state;
      const usersOfTheCluster = usersOfCluster[theClusterId];
      if (usersOfTheCluster) {
        const indexOfTheUser = findIndex(usersOfTheCluster, (user) => user.id === payload.user_id);
        if (indexOfTheUser !== -1) {
          usersOfTheCluster[indexOfTheUser] = payload.user;
        } else {
          usersOfTheCluster.push(payload.user);
        }
      } else {
        usersOfCluster[theClusterId] = [payload.user];
      }
      const { user: u } = payload;
      const { usersByID: drivers } = state;
      u.cluster_roles.forEach((cr) => {
        if (cr.role === 'DRIVER' || cr.role === 'SEASONAL_DRIVER') {
          drivers[u.id] = u;
        }
      });
      return {
        ...state,
        usersOfCluster: {
          ...usersOfCluster,
        },
        usersByID: {
          ...drivers,
        },
      };
    }
    case Actions.USER_UNLINKED: {
      const newUoc = { ...state.usersOfCluster };
      newUoc[payload.clusterID] = (newUoc[payload.clusterID] || []).filter((u) => u.id !== payload.userID);
      const newDrivers = { ...state.usersByID };
      delete newDrivers[payload.userID];
      return {
        ...state,
        usersOfCluster: newUoc,
        usersByID: newDrivers,
      };
    }
    case Actions.DRIVER_FOCUS:
      return {
        ...state,
        focusedDriverId: action.id,
      };
    case Actions.PARTNER_TOKEN_SUCCESS:
    case Actions.HAS_PARTNER_TOKEN_SUCCESS: {
      const partners = state.partners || [];
      const partnerName = action.partnerName;
      return {
        ...state,
        partners: [...partners].includes(partnerName) ? partners : [...partners, partnerName],
      };
    }
    case Actions.USER_LINK_EXISTING_TAG: {
      const user = state.usersByID[payload.userId];
      return {
        ...state,
        usersByID: {
          ...state.usersByID,
          [payload.userId]: { ...user, tags: [...(user.tags || []), payload.tag] },
        },
      };
    }
    case Actions.USER_UNLINK_TAG: {
      const user = state.usersByID[payload.userId];
      return {
        ...state,
        usersByID: {
          ...state.usersByID,
          [payload.userId]: {
            ...user,
            tags: (user.tags || []).filter((tag) => tag.id !== payload.tagId),
          },
        },
      };
    }
    case Actions.LOGIN_ERROR:
    case Actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default user;
