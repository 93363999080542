import { KeyboardEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { ColorName, colors } from '@karnott/colors';
import { UIHooks } from '@karnott/hooks';
import { DeleteIcon, KIcon, PenIcon, SaveIcon } from '@karnott/icons';
import { fontFamily, pixelSize, pixelSpacing, spacing, size as unitLessSize } from '@karnott/theme';
import { Effects as InputEffects } from './effects';

const Container = styled.div<{
  full: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: ${fontFamily()};
  font-weight: normal;
  flex: ${({ full }) => (full ? 1 : 'none')};
  max-width: 100%;
  min-width: 0;
`;

const TitleContainer = styled.div<{
  $disabled: boolean;
  full: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  flex: ${({ full }) => (full ? 1 : 'none')};
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  max-width: 100%;
`;

const InputContainer = styled.div<{
  full: boolean;
}>`
  display: flex;
  box-sizing: border-box;
  border: 1px solid;
  border-color: ${colors('grey', 'light')};
  border-radius: 5px;
  padding: 3px;
  flex-direction: row;
  width: ${({ full }) => (full ? '100%' : 'inherit')};
`;

const Input = styled.input<{
  $size: Parameters<typeof pixelSize>[0];
  $color: ColorName;
}>`
  padding: 0;
  font-size: ${({ $size }) => pixelSize($size)};
  color: ${({ $color }) => colors($color)};
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  font-family: ${fontFamily()};
  overflow: hidden;
  font-weight: normal;
`;

const Title = styled.div<{
  $color: ColorName;
  size: Parameters<typeof pixelSize>[0];
  opened: boolean;
  $fontWeight?: number | string;
  isHovered: boolean;
}>`
  opacity: ${({ opened }) => (opened ? 0 : 1)};
  font-size: ${({ size }) => pixelSize(size)};
  margin: ${pixelSpacing('xSmall')} 0;
  padding-left: 1px;
  min-height: ${({ size }) => unitLessSize(size) + spacing('xSmall')}px;
  color: ${({ $color, isHovered, title }) => (title ? colors($color, isHovered ? 400 : 500) : colors('grey', 600))};
  font-weight: ${({ $fontWeight }) => ($fontWeight ? $fontWeight : '100')};
  font-style: ${({ title }) => (title ? 'normal' : 'italic')};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const ActionsContainer = styled.div<{
  size: Parameters<typeof pixelSize>[0];
}>`
  display: flex;
  justify-content: space-around;
  min-width: ${({ size }) => unitLessSize(size) * 2}px;
  flex-direction: row;
  align-items: center;
  gap: ${pixelSpacing('xSmall')};
  margin-right: ${pixelSpacing('xSmall')};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const EditIcon = styled.div`
  flex-shrink: 0;
`;

type Props = {
  /** An icon to show next to the text on hover */
  Icon?: KIcon;
  /** Size of the font */
  size?: Parameters<typeof pixelSize>[0];
  /** The current text */
  currentValue: string;
  /** Can the value be edited */
  disabled?: boolean;
  /** Function that determines whether the current value is valid or not */
  isValidValue?: (value: string) => boolean;
  /** Define the input/title with width at 100% */
  full?: boolean;
  /** The input’s placeholder */
  placeholder?: string;
  /** Text color */
  color?: ColorName;
  /** Async function called when the submit button is pressed, before removing the editing state */
  onSubmit?: (value: string) => Promise<void>;
  /** Tuple to override (take control of) the open/close hook (editing state) */
  overrideOpenCloseHook?: [isOpen: boolean, open: () => void, close: () => void];
  /** Font weight of the text */
  titleFontWeight?: 200 | 400 | 600 | string;
};

/** A text value that can be edited on click */
export function EditableText({
  Icon = PenIcon,
  size = 'regular',
  currentValue,
  disabled = false,
  isValidValue,
  full = false,
  placeholder = '',
  color = 'black',
  onSubmit,
  overrideOpenCloseHook,
  titleFontWeight = 400,
}: Props) {
  const [_isOpen, _open, _close] = UIHooks.useOpenCloseState(false);
  const [containerRef, isHovered] = UIHooks.useHover<HTMLDivElement>();
  const [value, onValueChange] = InputEffects.useInputValue(currentValue);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    onValueChange({ target: { value: currentValue } });
  }, [currentValue, onValueChange]);

  const [isOpen, open, close] = useMemo(() => {
    if (overrideOpenCloseHook) {
      const [overrideIsOpen, overrideOpen, overrideClose] = overrideOpenCloseHook;
      if (overrideIsOpen !== undefined && overrideIsOpen !== null && overrideOpen && overrideClose) {
        const open = () => {
          if (!disabled) {
            overrideOpen();
          }
        };
        return [overrideIsOpen, open, overrideClose];
      }
    }
    const open = () => {
      if (!disabled) {
        _open();
      }
    };
    return [_isOpen, open, _close];
  }, [_isOpen, _open, _close, overrideOpenCloseHook, disabled]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const onSaveCB = useCallback(() => {
    if (isValidValue && !isValidValue(value)) {
      onValueChange({ target: { value: currentValue } });
      close();
    } else {
      onSubmit &&
        onSubmit(value).then(() => {
          close();
        });
    }
  }, [value, currentValue, onValueChange, isValidValue, close, onSubmit]);

  const onBlurCB = useCallback(() => {
    onValueChange({ target: { value: currentValue } });
    close();
  }, [onValueChange, currentValue, close]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        onSaveCB();
      } else if (e.key === 'Escape') {
        onBlurCB();
      }
    },
    [onBlurCB, onSaveCB],
  );

  return (
    <Container full={full}>
      <TitleContainer onClick={() => !isOpen && open()} $disabled={disabled} ref={containerRef} full={full}>
        {isOpen ? (
          <InputContainer onClick={(e) => e.preventDefault()} onKeyDown={handleKeyDown} full={full}>
            <Input
              $color={color}
              $size={size}
              onChange={onValueChange}
              value={value}
              placeholder={placeholder}
              ref={inputRef}
            />
            <ActionsContainer size={size}>
              <IconContainer onClick={() => onBlurCB()}>
                <DeleteIcon
                  size={unitLessSize(size) * 0.9}
                  color={colors('white')}
                  backgroundColor={colors('grey', 'light')}
                  circled={true}
                />
              </IconContainer>
              <IconContainer onClick={() => onSaveCB()}>
                <SaveIcon
                  size={unitLessSize(size) * 0.9}
                  color={colors('white')}
                  backgroundColor={colors('green')}
                  circled={true}
                />
              </IconContainer>
            </ActionsContainer>
          </InputContainer>
        ) : (
          <Title
            title={currentValue}
            isHovered={isHovered}
            $color={color}
            size={size}
            opened={isOpen}
            $fontWeight={titleFontWeight}
          >
            {currentValue || placeholder}
          </Title>
        )}
        {isHovered && !disabled && !isOpen ? (
          <EditIcon>
            <Icon size={unitLessSize(size) / 1.5} color={colors('grey', 300)} />
          </EditIcon>
        ) : null}
      </TitleContainer>
    </Container>
  );
}
